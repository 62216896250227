import React, { useMemo } from "react";
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import {urls} from "../constants";

const base_url = urls.base_url
const study_summary_url = `${base_url}/study_summary`;

const sendPostRequest = (req, auth) => {
    fetch(study_summary_url, {
        method: 'POST',
        headers:{
            'Authorization': auth,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
    })
    .then(() => {
        window.location.reload();
    }) 
    .catch(err=>{
        console.log("Failed to post request:", err);
    }, [])
};

const emptyRegExp = /^\s*$/;
const linkRegExp = /^[\w\d]+$/;

const StudySummaryManagementCard = ({studySummary, deleteCurrStudySummary, authToken}) => {
    let isNew = false;
    if ("isNew" in studySummary) {
        isNew = true;
        delete studySummary["isNew"];
    }

    let [editing, setEditing] = useState(isNew);
    let [header, setHeader] = useState(studySummary["Header"]);
    let [link, setLink] = useState(studySummary["Link"]);
    let [about, setAbout] = useState(studySummary["About"]);

    const handleHeaderChange = (event) => {setHeader(event.target.value);}
    const handleLinkChange = (event) => {setLink(event.target.value);}
    const handleAboutChange = (event) => {setAbout(event.target.value);}

    // Memos that return true if error
    const headerError = useMemo(() => emptyRegExp.test(header), [header]);
    const linkError = useMemo(() => emptyRegExp.test(link) || !linkRegExp.test(link), [link]);
    const aboutError = useMemo(() => emptyRegExp.test(about), [about]);
    const inputError = useMemo(
        () => headerError || linkError || aboutError,
        [headerError, linkError, aboutError]
    );

    // Memos that return helper message depending on value
    const getHeaderHelperText = useMemo(
        () => (emptyRegExp.test(header) ? "Header cannot be empty" : ""),
        [header]
    );
    const getLinkHelperText = useMemo(
        () => (emptyRegExp.test(link) ? "Link cannot be empty" : 
                !linkRegExp.test(link) ? "Link can only contain alphanumerics (a-z, A-Z, 0-9) and underscores" : ""),
        [link]
    );
    const getAboutHelperText = useMemo(
        () => (emptyRegExp.test(about) ? "About cannot be empty" : ""),
        [about]
    );

    const handleDeleteClick = () => {
        if (window.confirm('Are you sure you wish to delete this?')) {
            // on delete, make header and about fields empty
            let deletion = {"ID":studySummary["ID"],
                "Header":"","About":"",
                "Link":studySummary["Link"]};
            sendPostRequest(deletion, authToken);
            deleteCurrStudySummary();
        }
    };

    const handleEditClick = () => { setEditing(true); };

    const handleCancelClick = () => {
        // if this was a newly created study summary, then delete it when canceling
        if (isNew) {
            deleteCurrStudySummary();
            return;
        }

        // on cancel, reset to initial/saved study summary information
        setHeader(studySummary["Header"]);
        setLink(studySummary["Link"]);
        setAbout(studySummary["About"]);

        setEditing(false); 
    };

    const handleSaveClick = () => {
        // on save, update study summary to new changes
        studySummary["PreviousLink"] = studySummary["Link"];
        studySummary["Header"] = header;
        studySummary["Link"] = link;
        studySummary["About"] = about;

        // send call to update backend with changes
        sendPostRequest(studySummary, authToken);

        setEditing(false);
    };

    return (
        <Grid item xs="6">
        {editing ?
        // Form inside grid
        // checks for editing, ensure no empty fields and link is appropriate
        <div className="mt-lg-5">
            <h3 className="study_header">
                <TextField defaultValue={header} id="outlined-basic" label="Header"
                    onChange={handleHeaderChange} variant="outlined" error={headerError}
                    helperText={getHeaderHelperText}/>
            </h3>
            <p className="about_text fs-4">
                <TextField defaultValue={link} id="outlined-basic" label="Link"
                    onChange={handleLinkChange} variant="outlined" error={linkError}
                    helperText={getLinkHelperText}/>
            </p>
            <hr/>
            <p className="about_text fs-4">
                <TextField defaultValue={about} id="outlined-basic" label="About" multiline fullWidth
                    onChange={handleAboutChange} variant="outlined" error={aboutError}
                    helperText={getAboutHelperText}/>
            </p>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button variant="outlined" color="error" startIcon={<CancelIcon />}
                    onClick={handleCancelClick}>Cancel</Button>
                <Button variant="outlined" startIcon={<SaveIcon />}
                    onClick={handleSaveClick} disabled={inputError}>Save</Button>
            </Stack>
        </div>
        :
        <div className="mt-lg-5">
            <h3 className="study_header">{header}</h3>
            <p className="about_text fs-4">{link}</p>
            <hr/>
            <p className="about_text fs-4">{about}</p>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Button variant="outlined" color="error" startIcon={<DeleteIcon />}
                    onClick={handleDeleteClick}>Delete</Button>
                <Button variant="outlined" startIcon={<EditIcon />}
                    onClick={handleEditClick}>Edit</Button>
            </Stack>
        </div>
        }
        </Grid>
    );
}

export function StudySummaryManagement(props) {
    const [studySummaryList, setStudySummaryList] = useState([]);
    const [largestID, setLargestID] = useState(-1);

    useEffect(() => {
        fetch(study_summary_url, {headers:{}})
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setStudySummaryList(data.sort((a, b) => a["ID"] - b["ID"]));
            setLargestID(data[data.length - 1]["ID"]);
        }).catch(err=>{
            console.log("Failed to fetch study summary:", err);
        }, [])
    }, []);

    const deleteStudySummary = (id) => {
        console.log("Deleting " + id)
        let newStudySummaryList = studySummaryList.filter(o => {
            return o["ID"] !== id;
        });
        setStudySummaryList(newStudySummaryList);
        setLargestID(newStudySummaryList[newStudySummaryList.length - 1]["ID"]);
    }

    const createStudySummary = () => {
        let newStudySummary = {
            "ID": largestID + 1,
            "Header": "", "About": "", "Link": "", "isNew": true
        }

        setStudySummaryList([...studySummaryList, newStudySummary])
        setLargestID(largestID + 1);
    }

    return (
        <div style={{ height: "auto", width: "100%", marginBottom: 30 }} >
            <h3 className="fs-3">Study Summary Management</h3>
            <Box flexGrow="1">
                <Grid container spacing={3}>
                    {studySummaryList.map(studySummary => (
                        <StudySummaryManagementCard
                            key={studySummary["ID"]}
                            studySummary={studySummary}
                            deleteCurrStudySummary={() => deleteStudySummary(studySummary["ID"])}
                            authToken={props.authToken}/>
                    ))}
                    <Grid item xs="6">
                        <Button fullWidth size="large" variant="contained" startIcon={<AddCircleIcon />}
                            onClick={createStudySummary}/>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}