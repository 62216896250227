/*
 * Contributors: Fred Lionetti
 *
 * Libraries used: None
 *
 * File description: API call urls that can be used to access different lambdas
 */

// dev_constants.js

export const isProd = false;

const base_url = 'https://j5me5n49j9.execute-api.us-west-1.amazonaws.com/Prod'

export const urls = {
    login_url:              "https://capchd.auth.us-west-1.amazoncognito.com/login?client_id=33pko4bpf1qrsdkqidi4mmarsh&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://dev-capchd.sdsc.edu/",
    identify_pool_id:       'us-west-1:3b1e11b4-ae3a-40a7-8f67-9f173b90ed3f',
    cognito_url:            'cognito-idp.us-west-1.amazonaws.com/us-west-1_1Nvwa50B4',
    base_url_query_table:   `${base_url}/query_tables`, //query dynamodb
    base_url:               `${base_url}`, //count-studies-app
    base_download_url:      `${base_url}/download?`, //capchd_dicom_download
    base_url_upload_tab:    `${base_url}/update_tab`,
    study_bucketName:       'capchd-dicom-dev-db',
    csv_bucketName:         'capchd-csv-dev-bucket',
    data_usage_agreement_bucketName: 'capchd-data-usage-agreement-dev-bucket',
};

export const tableNames = {
    patient:        'dev_Patient',
    cmr:            'dev_CMR',
    cath:           'dev_Cath',
    cpet:           'dev_CPET',
    ecg:            'dev_ECG',
    echo:           'dev_ECHO',
    procedure:      'dev_Procedure',
    clinicalnotes:  'dev_ClinicalNotes'
};

export const validCognitoGroups = ['capchd-admin', 'capchd-download', 'capchd-edit', 'capchd-upload'];         